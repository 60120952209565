<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button @click="$router.push({ name: 'systemManagementEmployee', query: { activeName: $route.query.tableActiveName } })">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="160px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="用户信息">
          <el-col v-if="$route.params.editMode === 'add' && viewEdit()" :span="12">
            <el-form-item prop="firmCode" label="所属企业">
              <ics-item-inner :prop="appForm.companyName" :format="utils.isEffectiveCommon">
                <el-select v-model="appForm.firmCode" placeholder="请选择企业名称/编码" :disabled="disabledInfo" filterable @change="(val)=>firmChange(val)">
                  <el-option v-for="item in firmList" :key="item.itFirmCode" :label="`${item.companyName}（${item.companyCode}）`" :value="item.itFirmCode" />
                </el-select>
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode !== 'add'" :span="12">
            <el-form-item label="所属企业">
              <p>{{ utils.isEffectiveCommon(appForm.companyName) }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode === 'add' && viewEdit()" :span="12">
            <el-form-item label="所属角色">
              <el-select v-model="appForm.roleCode" placeholder="请选择角色名称/编码" :disabled="disabledRole" multiple filterable>
                <el-option v-for="item in roleList" :key="item.roleCode" :label="`${item.roleName}（${item.code}）`" :value="item.roleCode" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode !== 'add'" :span="12">
            <el-form-item label="所属角色">
              <p>{{ utils.roleFormat(appForm.roleList) }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item prop="name" label="姓名">
              <ics-item-inner :prop="appForm.name" :format="utils.isEffectiveCommon">
                <el-input v-model="appForm.name" placeholder="请输入姓名" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode === 'add' && viewEdit()" :span="12">
            <el-form-item prop="mobile" label="手机号码">
              <ics-item-inner :prop="appForm.mobile" :format="utils.isEffectiveCommon">
                <el-input v-model="appForm.mobile" :disabled="disabledInfo" placeholder="请输入手机号码" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode !== 'add'" :span="12">
            <el-form-item label="手机号码">
              <p>{{ utils.isEffectiveCommon(appForm.mobile) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="$route.params.editMode === 'add'" prop="email" label="个人邮箱">
              <ics-item-inner :prop="appForm.email" :format="utils.isEffectiveCommon">
                <el-input v-model="appForm.email" :disabled="disabledInfo" placeholder="请输入个人邮箱" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode !== 'add'" :span="12">
            <el-form-item label="个人邮箱">
              <p>{{ utils.isEffectiveCommon(appForm.email) }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode === 'add'" :span="12">
            <el-form-item prop="idCardNo" label="身份证号码">
              <ics-item-inner :prop="appForm.idCardNo" :format="utils.isEffectiveCommon">
                <el-input v-model="appForm.idCardNo" placeholder="请输入身份证号码" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="$route.params.editMode !== 'add'" :span="12">
            <el-form-item label="身份证号码">
              <p>{{ utils.isEffectiveCommon(appForm.idCardNo) }}</p>
            </el-form-item>
          </el-col>
          <ics-button-inner v-if="viewEdit()" :loading="loading.submit" cancel-title="返回" submit-title="提交" :show-cancel="false" @submit="submitForms" />
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  mixins: [routeEnterMixin],
  data () {
    const validatePhone  = (rule, value, callback) => {
      if (/^\d{3}-\d{8}|\d{4}-\d{7,8}$/.test(value) || /^1[3|4|5|7|8|9][0-9]\d{8}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号码'))
      }
    }
    return {
      employeeCode: this.$route.query.employeeCode,
      appForm: {
        password: '',
        firmCode: '',
        companyName: '',
        roleCode: [],
        roleName: [],
        name: '',
        mobile: '',
        email: '',
        idCardNo: '',
        remark: ''
      },
      disabledRole: true,
      disabledInfo: false,
      firmList: [],
      roleList: [],
      loading: {
        detail: false,
        submit: false
      },
      rules: {
        name: this.changeRules({ name: '姓名', required: true }),
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        email: this.changeRules({ name: '个人邮箱', required: true }),
        idCardNo: this.changeRules({ name: '身份证号码', required: true }),
        firmCode: [
          this.rulesToolkit.rules.required({ name: '所属企业', trigger: 'change' })
        ],
      }
    }
  },
  created () {
    if (this.employeeCode) {
      this.getDetail()
    }
    this.firmBaseList()
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.system.employee.detail({ employeeCode: this.employeeCode }).then(result => {
        this.disabledInfo = true
        const data = result.data.data || {}
        this.appForm.companyName = data.companyName
        this.appForm.firmCode = data.companyCode
        this.appForm.account = data.account
        this.appForm.name = data.name
        this.appForm.mobile = data.mobile
        this.appForm.email = data.email
        this.appForm.idCardNo = data.idCardNo
        this.appForm.roleList = data.roleList
        const roleDate = []
        const roleNameDate = []
        if (!this._.isEmpty(data.roleList)) {
          data.roleList.forEach(item => {
            roleDate.push(item.roleCode)
            roleNameDate.push({ roleCode: item.roleCode, roleName: item.roleName })
          })
        }
        this.appForm.roleName = roleNameDate || []
        this.appForm.roleCode = roleDate
        if (data.itFirmCode) {
          this.appForm.firmCode = data.itFirmCode
          this.disabledRole = false
          this.roleBaseList(data.itFirmCode)
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    // 获取企业
    firmBaseList () {
      this.api.admittance.getFirmByAppKey(this.$route.query.appKey).then(result => {
        this.firmList = result.data.data || []
      }).finally(() => {
      })
    },
    // 获取角色
    roleBaseList (itFirmCode) {
      this.api.system.role.findRole(itFirmCode).then(result => {
        this.roleList = result.data.data || []
      }).finally(() => {
      })
    },
    firmChange (val) {
      this.appForm.roleCode = ''
      this.disabledRole = false
      this.roleBaseList(val)
    },
    submitForms () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          this.loading.submit = true
          const firm = this._.find(this.firmList, { itFirmCode: this.appForm.firmCode }) || {}
          const roleList = []
          if (this.appForm.roleCode) {
            this.appForm.roleCode.forEach(item => {
              roleList.push({
                roleCode: item,
                appKey: firm.type,
                assignRole: 0,
                editRole: 0
              })
            })
          }
          const data = {
            firmCode: this.appForm.firmCode,
            appKey: firm.type,
            role: roleList,
            userName: this.appForm.name,
            phone: this.appForm.mobile,
            idCardNo: this.appForm.idCardNo,
            email: this.appForm.email,
            status: '20'
          }
          let api = this.api.system.employee.addUser
          if (this.employeeCode) {
            data.employeeCode = this.employeeCode
            api = this.api.system.employee.update
          }
          api(data).then(result => {
            if (result.data.success === true) {
              this.loading = false
              this.$message.success('操作成功')
              this.$router.back()
            } else {
              this.loading.submit = false
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
